import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Posts from "../components/posts"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>This page got frogged!</h1>
    <h2>Rip it, rip it, ribbit!</h2>
    <p>I'm afraid you ended up on a page that's gone. My fault, keeping a wrong link alive somewhere. I'm sorry this happened :( Let's find a new project to make together!</p>
    <p>Here's some of the latest patterns to make or some posts to read:</p>
    <Posts></Posts>
  </Layout>
)

export default NotFoundPage
