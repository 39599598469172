// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

import {graphql, Link, StaticQuery} from "gatsby";
import PostsStyles from "./posts.module.css";
import Img from "gatsby-image";


const Posts = () => (
  <div className={PostsStyles.latest}>
    <h2>Latest from the blog</h2>
    <StaticQuery
      query={graphql`
          {
            allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, limit: 4) {
              edges {
                node {
                  fields {
                    slug
                  }
                  excerpt(pruneLength: 250)
                  frontmatter {
                    date(formatString: "DD/MM/yy")
                    title
                    categories
                    featuredImage {
                      childImageSharp {
                        fluid(maxWidth: 800) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
      render={data => data.allMarkdownRemark.edges.map(edge => {
        let featuredImgFluid = edge.node.frontmatter.featuredImage.childImageSharp.fluid
        return(
          <article>
            <Link to={edge.node.fields.slug}><Img className={PostsStyles.image} fluid={featuredImgFluid} /></Link>
            <span className={PostsStyles.meta}>{edge.node.frontmatter.date} - <Link to={"/"} className={PostsStyles.cat}>{edge.node.frontmatter.categories}</Link></span>
            <h3><Link to={edge.node.fields.slug}>{edge.node.frontmatter.title}</Link></h3>
            <p className={PostsStyles.intro}>{edge.node.excerpt}</p>
            <Link to={edge.node.fields.slug} className={PostsStyles.readmore}>Read more</Link>
          </article>
        )
      })}
    ></StaticQuery>
  </div>
)

export default Posts
